import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { gerarRelatorioRacing } from '../../../services/requests/relatorio';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { gerarRelatorioEstoqueErro, gerarRelatorioEstoqueSucesso } from './actions';
import { GERAR_RELATORIO_ESTOQUE } from './constants';

function* relatorioEstoque() {
  const { filtros, relatorio } = yield select((state) => state.RelatoriosEstoque);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.RelatoriosEstoque]);
  const { modelo, tipo, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  query = {
    filtro: {
      ...montarFiltroParaApi(filtros),
      colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
      tipo,
      modelo,
    },
    ordenacao: {
      campo: preferencias?.ordenarPor || 'id',
      ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
    },
  };

  try {
    const { data, headers } = yield call(gerarRelatorioRacing, ProgramaCodigo.RelatoriosEstoque, query, tipo);

    const contentType = headers['content-type'];

    if (contentType.includes('application/json')) {
      yield put(
        gerarRelatorioEstoqueSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    } else {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioEstoqueSucesso());
        window.open(url, '_blank');
      }
    }
  } catch (e) {
    yield put(gerarRelatorioEstoqueErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'));
  }
}

export function* watchRelatorioEstoque() {
  yield takeLatest(GERAR_RELATORIO_ESTOQUE, relatorioEstoque);
}

function* relatoriosEstoqueSaga() {
  yield all([fork(watchRelatorioEstoque)]);
}

export default relatoriosEstoqueSaga;
