import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { gerarRelatorioRacing } from '../../../services/requests/relatorio';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { gerarRelatorioLogErro, gerarRelatorioLogSucesso } from './actions';
import { GERAR_RELATORIO_LOG } from './constants';

function* relatorioLog() {
  const { filtros, relatorio } = yield select((state) => state.RelatoriosLog);
  const { modelo, tipo, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  query = {
    filtro: {
      ...montarFiltroParaApi(filtros),
      colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
      tipo,
      modelo,
    },
    ordenacao: {
      campo: 'created_at',
      ordem: 'ASC',
    },
  };

  try {
    let { data, headers } = yield call(gerarRelatorioRacing, ProgramaCodigo.RelatoriosLog, query, tipo);

    if (headers['content-type'].includes('application/json')) {
      yield put(
        gerarRelatorioLogSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    } else {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: headers['content-type'] || 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioLogSucesso());
        window.open(url, '_blank');
      }
    }
  } catch (e) {
    yield put(gerarRelatorioLogErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'));
  }
}

export function* watchRelatorioLog() {
  yield takeLatest(GERAR_RELATORIO_LOG, relatorioLog);
}

function* relatoriosLogSaga() {
  yield all([fork(watchRelatorioLog)]);
}

export default relatoriosLogSaga;
