import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { SET_LIMIT_PAGE, SET_TABLE_ORDER } from '../../../redux/table/constants';
import { gerarRelatorioRacing } from '../../../services/requests/relatorio';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { obterProdutosBaseAsync } from '../requests';
import {
  gerarRelatorioProdutosErro,
  gerarRelatorioProdutosSucesso,
  obterProdutoErro,
  obterProdutoSucesso,
} from './actions';
import { FILTRAR_PRODUTO, GERAR_RELATORIO_PRODUTO, LISTAR_PRODUTO } from './constants';

function* listarTodosProduto() {
  try {
    const filtro = yield select((state) => state.Produtos.filtros);
    const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.Produtos]);

    let query = { filtro: montarFiltroParaApi(filtro) };

    if (preferencias) {
      query.ordenacao = {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      };
      query.paginacao = {
        pagina: preferencias?.page || 1,
        limite: preferencias?.limit || 50,
      };
    }

    const { data } = yield call(obterProdutosBaseAsync, query);
    yield put(obterProdutoSucesso(data));
  } catch (error) {
    yield put(obterProdutoErro(error.response?.data?.message || error.message));
  }
}

function* relatorioProdutos() {
  const { filtros, relatorio } = yield select((state) => state.Produtos);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.Produtos]);
  const { modelo, tipo, comFiltro, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  if (comFiltro) {
    query = {
      filtro: {
        ...montarFiltroParaApi(filtros),
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
      ordenacao: {
        campo: preferencias?.ordenarPor || 'nome',
        ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
      },
    };
  } else {
    query = {
      filtro: {
        colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
        tipo,
        modelo,
      },
    };
  }

  try {
    const { data, headers } = yield call(gerarRelatorioRacing, ProgramaCodigo.Produtos, query, tipo);

    if (headers['content-type'].includes('application/json')) {
      yield put(
        gerarRelatorioProdutosSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    } else {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: headers['content-type'] || 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioProdutosSucesso());
        window.open(url, '_blank');
      }
    }
  } catch (e) {
    yield put(gerarRelatorioProdutosErro('Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.'));
  }
}

function* ordenarProduto({ payload }) {
  if (payload.programaId === ProgramaCodigo.Produtos) {
    yield call(listarTodosProduto);
  }
}

function* mudarLimitePaginacao({ payload }) {
  if (payload.programaId === ProgramaCodigo.Produtos) {
    yield call(listarTodosProduto);
  }
}

export function* watchListarProduto() {
  yield takeLatest(LISTAR_PRODUTO, listarTodosProduto);
}

export function* watchFiltrarProduto() {
  yield takeLatest(FILTRAR_PRODUTO, listarTodosProduto);
}

export function* watchRelatorioProduto() {
  yield takeLatest(GERAR_RELATORIO_PRODUTO, relatorioProdutos);
}

export function* watchOrdenarProduto() {
  yield takeLatest(SET_TABLE_ORDER, ordenarProduto);
}

export function* watchLimitePaginacao() {
  yield takeLatest(SET_LIMIT_PAGE, mudarLimitePaginacao);
}

function* ProdutoSaga() {
  yield all([
    fork(watchListarProduto),
    fork(watchFiltrarProduto),
    fork(watchRelatorioProduto),
    fork(watchOrdenarProduto),
    fork(watchLimitePaginacao),
  ]);
}

export default ProdutoSaga;
