import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { ProgramaCodigo } from '../../../constants/enums/programa-codigo.enum';
import { gerarRelatorioRacing } from '../../../services/requests/relatorio';
import { montarFiltroParaApi } from '../../../utils/api-filter';
import { gerarRelatorioMonitoramentoCarenciaErro, gerarRelatorioMonitoramentoCarenciaSucesso } from './actions';
import { GERAR_RELATORIO_MONITORAMENTO_CARENCIA } from './constants';

function* relatorioMonitoramentoCarencia() {
  const { filtros, relatorio } = yield select((state) => state.RelatoriosMonitoramentoCarencia);
  const preferencias = yield select((state) => state.TableConfig[ProgramaCodigo.RelatoriosMonitoramentoCarencia]);
  const { modelo, tipo, colunas } = relatorio;
  const columns = colunas?.map((coluna) => coluna.value);
  let query = {};

  query = {
    filtro: {
      ...montarFiltroParaApi(filtros),
      colunas: Array.isArray(columns) && columns?.length > 0 ? columns : undefined,
      tipo,
      modelo,
    },
    ordenacao: {
      campo: preferencias?.ordenarPor || 'id',
      ordem: preferencias?.ordem?.toUpperCase() || 'ASC',
    },
  };

  try {
    const { data, headers } = yield call(
      gerarRelatorioRacing,
      ProgramaCodigo.RelatoriosMonitoramentoCarencia,
      query,
      tipo,
    );

    const contentType = headers['content-type'];

    if (contentType.includes('application/json')) {
      yield put(
        gerarRelatorioMonitoramentoCarenciaSucesso({
          titulo: 'Pedido de impressão salvo.',
          mensagem: 'Seu relatório está sendo gerado.',
        }),
      );
    } else {
      const url = URL.createObjectURL(
        new Blob([data], {
          type: contentType || 'application/pdf',
        }),
      );

      if (url) {
        yield put(gerarRelatorioMonitoramentoCarenciaSucesso());
        window.open(url, '_blank');
      }
    }
  } catch (e) {
    yield put(
      gerarRelatorioMonitoramentoCarenciaErro(
        'Ocorreu um erro ao tentar gerar o relatório. Tente novamente mais tarde.',
      ),
    );
  }
}

export function* watchRelatorioMonitoramentoCarencia() {
  yield takeLatest(GERAR_RELATORIO_MONITORAMENTO_CARENCIA, relatorioMonitoramentoCarencia);
}

function* relatoriosMonitoramentoCarenciaSaga() {
  yield all([fork(watchRelatorioMonitoramentoCarencia)]);
}

export default relatoriosMonitoramentoCarenciaSaga;
