import { AxiosResponse } from 'axios';
import { IOptions } from '../../components/Tabela';
import { TipoRelatorio } from '../../constants/enums/impressao.enum';
import { ProgramaCodigo } from '../../constants/enums/programa-codigo.enum';
import { montarQueryString } from '../../utils';
import api from '../api';

export interface HandleBotaoImprimir {
  colunas?: IOptions[] | { value: string; label: string; selecionado?: boolean }[];
  modelo?: number;
  tipo?: number;
  comFiltro?: boolean;
}

export const gerarRelatorio = (programaId: ProgramaCodigo, query = {}, tipo?: number) => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `relatorios/${programaId}/gerar${queryStr}`,
    method: 'GET',
    headers: {
      responseType: 'arraybuffer',
      dataType: 'blob',
    },
    responseType: Number(tipo) === TipoRelatorio.visualizar ? 'blob' : 'json',
  });
};

// Quando todos os programas forem alterados para o metodo racing deixar apenas uma função de gerar relatorio
export const gerarRelatorioRacing = (programaId: ProgramaCodigo, query = {}, tipo?: number) => {
  const queryStr = montarQueryString(query);

  return api.request({
    url: `relatorios/${programaId}/gerar${queryStr}`,
    method: 'GET',
    headers: {
      responseType: 'arraybuffer',
      dataType: 'blob',
    },
    responseType: 'blob',
  });
};

export const getPublicUrl = (chave: string, query = {}) => {
  const queryStr = montarQueryString(query);

  return api.get(`aws/public/${chave}${queryStr}`);
};

export const removerRelatorio = (id: number): Promise<AxiosResponse<{ affected: number }>> =>
  api.request({
    url: `relatorios/${id}`,
    method: 'DELETE',
  });
